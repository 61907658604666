import React, { useCallback } from 'react'
import Section from '~components/global/section'
import ReactPlayer from 'react-player'
import { css } from "@emotion/react"
import styled from '@emotion/styled'

const VideoModule = ({ video }) => {
  return (
    <div css={css`${iFrameContainer}`}>
      <div css={css`${iFrameStyles}`}>
        <ReactPlayer 
          url={video} 
          width={`100%`}
          height={`100%`}
          config={{
            youtube: {
              playerVars: {
                controls: 0,
              }
            },
            vimeo: {
            }
          }}
          controls={true}
        />
      </div>
    </div>
  )
}

const iFrameContainer = `
  position: relative; 
  padding-bottom: 56.25%;
  height: 0; 
  overflow: hidden; 
  max-width: 100%;
`

const iFrameStyles = `
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
`

export default VideoModule